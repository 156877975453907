// Import carousel scripts
import "../../carousel/js/app";

// Import images
function requireAll(r) {
	r.keys().forEach(r);
}
requireAll(require.context("../images/", true));

// Include jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// GA click tracking
$("section a, footer .partner-links a").click(function () {
	var $this = $(this);
	var hostName = $this[0].hostname;
	var protocol = $this[0].protocol;
	if (location.hostname != hostName && (protocol == "http:" || protocol == "https:")) {
		var fullHref = $this.attr("href");
		var href = $this.attr("href").split("?")[0];
		var target = $this.attr("target") ? $this.attr("target") : "";
		if (typeof ga != "undefined" && ga.getAll().length) {
			var tracker = ga.getAll()[0];
			var trackerName = tracker.get("name");
			var eventObject = {
				eventCategory: "Links",
				eventAction: "Click",
				eventLabel: href,
				dimension1: getSessionId(),
				dimension2: Date.now(),
			};
			ga(trackerName + ".send", "event", eventObject);
		}
		if (typeof gtag != "undefined") {
			gtag("event", "click", {
				link_url: href,
				session_id: getSessionId(),
				time_stamp: Date.now(),
			});
		}
		if (target.length == 0) {
			setTimeout(function () {
				location.href = fullHref;
			}, 200);
			return false;
		}
	}
});

// Email modal tracking
$("#emailModal").on("show.bs.modal", function () {
	sendModalEvent("Opened", $(this).attr("data-org"));
});
$("#emailModal").on("hide.bs.modal", function () {
	sendModalEvent("Closed", $(this).attr("data-org"));
});
$("#emailModal form").on("submit", function (e) {
	sendModalEvent("Submitted", $(this).attr("data-org"));
});

function sendModalEvent(e, org) {
	if (typeof ga != "undefined" && ga.getAll().length) {
		var tracker = ga.getAll()[0];
		var trackerName = tracker.get("name");
		var eventObject = {
			eventCategory: "Referrals",
			eventAction: "Email Modal",
			eventLabel: e,
			dimension1: getSessionId(),
			dimension2: Date.now(),
			dimension4: org,
		};
		ga(trackerName + ".send", "event", eventObject);
	}
	if (typeof gtag != "undefined") {
		gtag("event", "referral", {
			referral_source: "email modal",
			referral_action: e,
			session_id: getSessionId(),
			time_stamp: Date.now(),
			organization_name: org,
		});
	}
}

window.sendSearchParams = function (topic, topic_id, county, county_id) {
	if (typeof gtag != "undefined") {
		gtag("event", "search", {
			topic: topic,
			topic_id: topic_id,
			county: county,
			county_id: county_id,
			session_id: getSessionId(),
			time_stamp: Date.now(),
		});
	}
};

// Add area search form
const $navContainer = $(".header-common .bootstrap-navbar .container");
if ($navContainer.length) {
	$navContainer.append(
		'<div class="order-2 order-xl-1"><form action="../search/" method="get" class="d-flex align-items-center"><div><input type="text" name="q" class="form-control form-control-sm" placeholder="Search the Gateway" /></div><button type="submit" class="btn btn-default" title="Search"><span class="pbn-icon-search text-white"></span></button></form></div>'
	);
}
