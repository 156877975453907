// Include Bootstrap
import * as bootstrap from "bootstrap";

// Include jquery
//import $ from "jquery";
//window.jQuery = $;
//window.$ = $;

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(() => {
	NProgress.inc();
}, 1000);
window.addEventListener("load", () => {
	NProgress.done();
	clearInterval(interval);
});

// Import add this
import addthis from "addthis-snippet";

/******************************** Global functions *************************************/

// Dom ready function
window.domReady = function (callback) {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
};

// Get cookie function
window.getCookie = function (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "undefined";
};

// Set cookie function
window.setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	return cvalue;
};

// Session stuff
window.getSessionId = function () {
	return getCookie("SESSIONID") ? getCookie("SESSIONID") : setCookie("SESSIONID", guid(), 365);
};

// Get json data
window.getJsonData = function (id, clear) {
	var $el = document.getElementById(id);
	if ($el) {
		var json = $el.textContent || $el.innerText;
		var data = JSON.parse(json);
		if (clear) {
			$el.innerHTML = "";
		}
		return data;
	} else {
		return undefined;
	}
};

// Decode html
window.decodeHtml = function (html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
};

/******************************** Theme functions *************************************/

// Initialize add this
const $app = document.getElementById("app");
if ($app.dataset.addThis) {
	addthis({ pubid: "ra-633cd2cff82dae57" });
}

// Remove unwanted stylesheets
const $styles = document.querySelectorAll("link,style");

if ($styles.length) {
	let badIds = ["projectStyles", "tools2", "browserStyles", "flexpage_columns", "tabbed-horizontal", "portal"];
	Array.from($styles)
		.filter((el) => badIds.includes(el.id))
		.forEach((el) => {
			el.disabled = true;
			el.parentNode.removeChild(el);
		});
}

// Remove portal layout
const $portalLayout = document.querySelector("#content .layout");
if ($portalLayout) {
	$portalLayout.parentNode.removeChild($portalLayout);
}

// Remove empty content section
const $content = document.querySelector("#content");
if ($content && !$content.children.length) {
	$content.parentNode.removeChild($content);
}

// Remove empty design sections
const $sections = document.querySelectorAll(".main-header,.main-content,.main-footer");
if ($sections.length) {
	$sections.forEach(function ($section) {
		var $rows = $section.querySelectorAll(".row");
		$rows.forEach(function ($row) {
			if (!$row.children.length) {
				$row.parentNode.removeChild($row);
			}
		});
		if (!$section.querySelector(".row")) {
			$section.parentNode.removeChild($section);
		}
	});
}

// Pop out search
const $popout = document.querySelector(".header-top .popout-search");
if ($popout) {
	let $input = $popout.querySelector(".form-control");
	let $button = $popout.querySelector(".btn");
	$button.addEventListener("click", (e) => {
		var isHidden = window.getComputedStyle($input).display === "none";
		if (isHidden || !$input.value) {
			e.preventDefault();
			$input.classList.toggle("d-none", !isHidden);
		}
	});
}

// Sticky navbar
const $headerNav = document.getElementById("header-nav");
if ($headerNav) {
	let $headerTop = document.getElementById("header-top");
	let $headerMain = document.getElementById("header-main");
	function stickyNav() {
		if (window.scrollY >= $headerTop.offsetHeight + $headerMain.offsetHeight && window.innerWidth <= 768) {
			$headerNav.classList.add("fixed-top");
			document.body.style.paddingTop = $headerNav.offsetHeight + "px";
		} else {
			$headerNav.classList.remove("fixed-top");
			document.body.style.paddingTop = "0";
		}
	}
	window.addEventListener("scroll", () => {
		stickyNav();
	});
	window.addEventListener("resize", () => {
		stickyNav();
	});
	domReady(() => {
		stickyNav();
	});
}

// Hide visit
const $hideVisit = document.getElementById("hide-visit");
if ($hideVisit) {
	$hideVisit.addEventListener("click", (e) => {
		e.preventDefault();
		hideVisit();
	});
	document.addEventListener("keyup", (e) => {
		if (e.key === "Escape") {
			hideVisit();
		}
	});
	function hideVisit() {
		window.open("https://www.google.com", "_blank");
		window.location.href = "https://www.yahoo.com";
	}
}

// Fixed hide visit
if ($hideVisit.classList.contains("fixed")) {
	let $headerTop = document.getElementById("header-top");
	let $headerMain = document.getElementById("header-main");
	function moveHideVisit() {
		if (window.scrollY >= $headerTop.offsetHeight + $headerMain.offsetHeight && window.innerWidth <= 768) {
			$hideVisit.style.top = $headerNav.offsetHeight + "px";
		} else {
			$hideVisit.style.top = "0px";
		}
	}
	window.addEventListener("scroll", () => {
		moveHideVisit();
	});
	window.addEventListener("resize", () => {
		moveHideVisit();
	});
	domReady(() => {
		moveHideVisit();
	});
}

// Text resizing
const $textSizer = document.getElementById("text-sizer");
if ($textSizer) {
	$textSizer.querySelector(".size-1").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(1);
		setCookie("text_size", 1, 30);
	});
	$textSizer.querySelector(".size-2").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(2);
		setCookie("text_size", 2, 30);
	});
	$textSizer.querySelector(".size-3").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(3);
		setCookie("text_size", 3, 30);
	});
	function resizeText(size) {
		if (size != "undefined") {
			size = Number(size);
			$textSizer.querySelectorAll("a").forEach((el) => {
				el.classList.remove("current-size");
			});
			$textSizer.querySelector("a.size-" + size).classList.add("current-size");
			var $body = document.querySelector("body");
			switch (size) {
				case 1:
					$body.classList.remove("big");
					$body.classList.add("small");
					break;
				case 2:
					$body.classList.remove("small", "big");
					break;
				case 3:
					$body.classList.remove("small");
					$body.classList.add("big");
					break;
				default:
					$body.classList.remove("small", "big");
					break;
			}
		}
	}
	resizeText(getCookie("text_size"));
}

// Language selector
const $languageSelector = document.querySelector(".language-selector");
if ($languageSelector) {
	document.querySelectorAll(".gtranslate").forEach(function (el) {
		el.addEventListener("click", function (e) {
			e.preventDefault();
			doGTranslate(this.dataset.langPair);
		});
	});
	function doGTranslate(lang_pair) {
		if (lang_pair.value) lang_pair = lang_pair.value;
		if (lang_pair == "") return;
		var lang = lang_pair.split("|")[1];
		var plang = location.hostname.split(".")[0];
		if (plang.length != 2 && plang.toLowerCase() != "zh-cn" && plang.toLowerCase() != "zh-tw") plang = "en";
		location.href =
			location.protocol +
			"//" +
			(lang == "en" ? "" : lang + ".") +
			location.hostname.replace("www.", "").replace(RegExp("^" + plang + "\\."), "") +
			location.pathname +
			location.search;
	}
}

// Back to top
const $backToTop = document.getElementById("back-to-top");
if ($backToTop) {
	$backToTop.addEventListener("click", (e) => {
		e.preventDefault();
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
	window.addEventListener("scroll", () => {
		if (window.scrollY >= 400) {
			$backToTop.classList.remove("d-none");
			$backToTop.classList.add("d-block");
		} else {
			$backToTop.classList.remove("d-block");
			$backToTop.classList.add("d-none");
		}
	});
	function moveBackToTop() {
		if (window.__lc || window.Comm100API) {
			var $chatWidget = document.getElementById("chat-widget-container") || document.querySelector("[id^='comm100-button']");
			if ($chatWidget) {
				$backToTop.style.bottom = "20px";
				$backToTop.style.left = "20px";
				$backToTop.style.right = "auto";
			} else {
				setTimeout(moveBackToTop, 100);
			}
		}
	}
	domReady(() => {
		moveBackToTop();
	});
}

// Tooltips
const $toolTips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if ($toolTips.length) {
	$toolTips.forEach(($toolTip) => {
		new bootstrap.Tooltip($toolTip);
	});
}

// Form validation
const $forms = document.querySelectorAll(".needs-validation");
if ($forms.length) {
	$forms.forEach(($form) => {
		$form.addEventListener(
			"submit",
			(e) => {
				if (!$form.checkValidity()) {
					e.preventDefault();
					e.stopPropagation();
				}
				$form.classList.add("was-validated");
			},
			false
		);
	});
}

// Site modal
const $siteModal = document.getElementById("site-modal");
if ($siteModal) {
	let modalShown = getCookie("modal-shown-ct");
	if (modalShown == "undefined") {
		new bootstrap.Modal("#site-modal").show();
		let timeOut = $siteModal.dataset.modalTimeout ? $siteModal.dataset.modalTimeout : 30;
		setCookie("modal-shown-ct", 1, timeOut);
	}
}

/******************************** App specific functions *************************************/

// Print friendly change
const $printLink = document.querySelectorAll(".page-tools a.print");
if ($printLink.length) {
	$printLink.forEach((el) => {
		el.parentNode.style.display = "inline";
		el.innerText = "print page";
		el.title = "print page";
		el.addEventListener("click", (e) => {
			e.preventDefault();
			window.print();
		});
	});
}

// Move page tools menu as needed
const $pageTools = document.querySelector("#m-header .page-tools");
if ($pageTools) {
	let $parentNode = $pageTools.parentNode;
	if ($pageTools !== $parentNode.lastElementChild) {
		$parentNode.removeChild($pageTools);
		$parentNode.appendChild($pageTools);
	}
}

// Remove link label
const $propLink = document.querySelector(".content-detail .link");
if ($propLink) {
	$propLink.removeChild($propLink.childNodes[0]);
}

// Organize content detail page as needed
const $flexContainer = document.querySelector(".content-detail.flex-container");
if ($flexContainer) {
	let $leftColumn = $flexContainer.querySelector(".left-column:not(.bottom)");
	let $rightColumn = $flexContainer.querySelector(".right-column:not(.bottom)");
	function adjustColumnMargin() {
		if ($leftColumn && $rightColumn && $rightColumn.offsetHeight > $leftColumn.offsetHeight) {
			var $bottomColumn = $flexContainer.querySelector(".left-column.bottom");
			if ($bottomColumn) {
				if (window.innerWidth > 768) {
					$bottomColumn.style.marginTop = $leftColumn.offsetHeight - $rightColumn.offsetHeight + 10 + "px";
				} else {
					$bottomColumn.style.marginTop = 0;
				}
			}
		}
	}
	window.addEventListener("resize", () => {
		adjustColumnMargin();
	});
	domReady(() => {
		adjustColumnMargin();
	});
}

// Move detail tool items as needed
const $detailTools = document.querySelector("#content .detail-tools");
if ($detailTools) {
	// Share link (News, Event, Cases, Resource, Oppsguide org)
	let $shareLink = $detailTools.querySelector(".detail-share");
	if ($shareLink) {
		$pageTools.prepend($shareLink);
	}
	// Event specific
	if ($detailTools.classList.contains("calendar")) {
		// Still to do registrations ?
		let $eventProps = document.querySelector(".left-column .description .props:first-child");
		let $eventDates = $detailTools.querySelector(".event-date");
		let $cleCredit = $detailTools.querySelector(".cleCredit");
		let $download = $detailTools.querySelector(".download-link");
		if ($cleCredit) {
			$eventProps.prepend($cleCredit);
		}
		$eventProps.prepend($eventDates);
		$eventProps.append($download);
	}
	// Org specific
	if ($detailTools.classList.contains("organization")) {
		// cases-count, projects-count
	}
	$detailTools.parentNode.removeChild($detailTools);
}

// Find legal help
const $helpResults = document.querySelector(".find-legal-help-results");
if ($helpResults) {
	$helpResults.querySelectorAll(".find-legal-help-org-featured,.find-legal-help-org.top-org").forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();
			location.href = el.dataset.url;
		});
	});
	$helpResults
		.querySelectorAll(".find-legal-help-org-featured .list-inline-item a,.find-legal-help-org.top-org .list-inline-item a")
		.forEach((el) => {
			el.addEventListener("click", (e) => {
				e.stopPropagation();
			});
		});
}
